import React from "react";
import { Container, Button, Box, Grid, Divider } from "@mui/material";
import img2 from "./../../images/FullMontyPromo.jpg";
import img3 from "./../../images/2023BroadwayAwards.png";

import "./Upcoming.css";

const UpcomingSec = () => {
  return (
    <div style={{ background: "#0B0B0B", color: "#ecf0f3" }}>
      <Container>
        <Divider
          sx={{
            display: { lg: "flex", md: "flex", sm: "flex", xs: "flex" },
            background: "white",
          }}
        ></Divider>
      </Container>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "3em",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
            justifyContent: "center",
            mb: "1em",
          }}
        >
          <h1 className="tourTitle">
            The Full Monty: <br></br> The Broadway Musical{" "}
          </h1>
        </Box>
        <Box
          sx={{
            display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
            justifyContent: "center",
            mb: "1em",
          }}
        >
          <h1
            className="tourTitleMobile"
            style={{
              display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              justifyContent: "center",
            }}
          >
            The Full Monty: <br></br> The Broadway Musical
          </h1>
        </Box>
        <Box
          sx={{
            display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
            textAlign: "center",
            pl: "15em",
            pr: "15em",
            pb: "2em",
          }}
        >
          <p className="tourInfo">
            The Full Monty: The Broadway Musical, is a new up and coming musical
            starring Jamie McRoberts. More details regarding the performance and
            dates will be added shortly.{" "}
          </p>
        </Box>
      </Box>

      {/* Full Monty Pic for Md+ */}
      <Box
        sx={{
          display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
          justifyContent: "center",
          mb: "2em",
        }}
      >
        <a href="" aria-label="Maggie Link Tree" rel="noopener" target="_blank">
          <img
            style={{
              width: "20vw",
              height: "20vw",
            }}
            src={img2}
            alt="Maggie Promotion"
          />
        </a>
      </Box>

      {/* Maggie Picture for Sm- */}
      <Box
        sx={{
          display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
          justifyContent: "center",
        }}
      >
        <a
          href=""
          aria-label="Full Monty Social Media"
          rel="noopener"
          target="_blank"
        >
          <img
            style={{
              width: "65vw",
              height: "65vw",
              marginBottom: "1em",
            }}
            src={img2}
            alt="Full Monty Promo"
          />
        </a>
      </Box>

      {/* Learn More and News Button for Md+ */}
      <Box
        sx={{
          display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Button
          href=""
          aria-label="linktree for Maggie"
          rel="noopener"
          target="_blank"
          sx={{
            border: "solid 4px",
            borderColor: "#F3BE12",
            height: "45px",
            width: "200px",
            mb: "3em",
            "&:hover": {
              cursor: "pointer",
              background: "#F3BE12",
            },
          }}
        >
          <p className="buttonTextTour">All Info</p>
        </Button>
        {/* 
        <Button
          href="https://www.intermissionmagazine.ca/reviews/review-maggie-theatre-aquarius/"
          aria-label="Review for Maggie"
          rel="noopener"
          target="_blank"
          sx={{
            border: "solid 4px",
            borderColor: "#F3BE12",
            height: "45px",
            width: "200px",
            mb: "3em",
            ml: "2em",
            "&:hover": {
              cursor: "pointer",
              background: "#F3BE12",
            },
          }}
        >
          <p className="buttonTextTour"> Review</p>
        </Button> */}
      </Box>

      {/* Learn More and News Button for Sm- */}
      <Box
        sx={{
          display: { lg: "none", md: "none", sm: "none", xs: "flex" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Button
          href="https://linktr.ee/maggiemusical"
          aria-label="linktree for Maggie"
          rel="noopener"
          target="_blank"
          sx={{
            border: "solid 4px",
            borderColor: "#F3BE12",
            height: "45px",
            width: "200px",
            mb: "1em",
            "&:hover": {
              cursor: "pointer",
              background: "#F3BE12",
            },
          }}
        >
          <p className="buttonTextTour">All Info</p>
        </Button>

        {/* <Button
          href="https://www.intermissionmagazine.ca/reviews/review-maggie-theatre-aquarius/"
          aria-label="Review for Maggie"
          rel="noopener"
          target="_blank"
          sx={{
            border: "solid 4px",
            borderColor: "#F3BE12",
            height: "45px",
            width: "200px",
            mb: "2em",

            "&:hover": {
              cursor: "pointer",
              background: "#F3BE12",
            },
          }}
        >
          <p className="buttonTextTour">Review</p>
        </Button> */}
      </Box>

      {/* Divider */}
      <Container>
        <Divider
          sx={{
            display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
            background: "white",
          }}
        ></Divider>
      </Container>
    </div>
  );
};

export default UpcomingSec;
