import React from "react";
import "./Theatre.css";
import { Container, Button, Box, Grid } from "@mui/material";
import TheatreMobile from "./TheatreMobile";
import img1 from "./../../images/DoraAward.jpg";
import img2 from "./../../images/IntoTheWoods.jpg";
import img3 from "./../../images/AprilFools1.jpg";
import img4 from "./../../images/DrinkingAlone.jpg";
import img5 from "./../../images/RockOfAges.jpg";
import img6 from "./../../images/ForResting.jpg";

const TheatreSec = () => {
  return (
    <div className="heroTheatre">
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: { lg: "flex", md: "flex", sm: "flex", xs: "flex" },
            justifyContent: "center",
            textAlign: "center",
            mt: "5em",
            "&:hover": {
              cursor: "default",
            },
          }}
        >
          <Box
            sx={{
              display: { lg: "flex", md: "flex", sm: "flex", xs: "flex" },
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
                justifyContent: "center",
              }}
            >
              <h1 className="theatreTitle">Theatre & Accolades</h1>
            </Box>
            <Box
              sx={{
                display: { lg: "none", md: "none", sm: "none", xs: "flex" },
                justifyContent: "center",
              }}
            >
              <h1 className="theatreTitleMobile">Theatre & Accolades</h1>
            </Box>
            <Box
              sx={{
                display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                justifyContent: "center",
                pl: "15em",
                pr: "15em",
              }}
            >
              <p className="theatreInfo">
                After graduating from George Brown Theatre School’s Classical
                Theatre Conservatory Program, McRoberts professional career as a
                musical theatre performer would be recognized through many
                awards and accolades such as the Second City Comedy Award from
                her theatre school, A Dora Mavor Moore Award as part of the
                ensemble of The Wizard of Oz at Young People’s Theatre in
                Toronto, Ontario and several other nominations for her many on
                stage performances. Some of her favourite credits include Rock
                of Ages at Stage West Calgary, All Shook Up at Stage West
                Mississauga, The Baker’s Wife with Talk Is Free Theatre
                performed at the iconic Winter Garden Theatre in Toronto, April
                Fools at the Segal Center in Montreal where she was able to put
                her musical skills to work as an actor/musician and her
                performance in For Both Resting and Breeding which was performed
                in Adelaide, Australia.
              </p>
            </Box>
          </Box>
        </Grid>

        <Container
          sx={{
            mb: "2em",
          }}
        >
          <Grid
            sx={{
              display: { lg: "flex", md: "none", sm: "none", xs: "none" },
              justifyContent: "space-evenly",
              mt: "4em",
            }}
            container
          >
            <Grid item xl={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    maxWidth: 350,
                    borderRadius: "0px",
                  }}
                  src={img1}
                  alt="Bring Me Your 'A' Game Album Cover"
                />
                <Box sx={{ width: "450px", textAlign: "center" }}>
                  <p className="theatreInfo">
                    <b>Dora Award Winner</b> for “best ensemble” in the YPT
                    Production of The Wizard of Oz for her role as Glinda the
                    Good Witch (2016)
                  </p>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    maxWidth: 300,
                    borderRadius: "0px",
                  }}
                  src={img5}
                  alt="Rock Of Ages"
                />
                <Box sx={{ width: "450px", textAlign: "center" }}>
                  <p className="theatreInfo">
                    McRoberts backstage in her role as Regina in Rock of Ages
                    performed at Stage West Calgary (2017)
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: { lg: "flex", md: "none", sm: "none", xs: "none" },
              justifyContent: "space-evenly",
              mt: "3em",
            }}
            container
          >
            <Grid item xl={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    maxWidth: 500,
                    maxheight: 500,
                    borderRadius: "0px",
                  }}
                  src={img4}
                  alt="Bring Me Your 'A' Game Album Cover"
                />
                <Box sx={{ width: "500px", textAlign: "center" }}>
                  <p className="theatreInfo">
                    Nominated for <b> Best Leading Actress </b> in a play by
                    Broadway World for her performance in Drinking Alone by Norm
                    Foster (2014)
                  </p>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    maxWidth: 500,
                    maxheight: 500,
                    borderRadius: "0px",
                  }}
                  src={img3}
                  alt="Bring Me Your 'A' Game Album Cover"
                />
                <Box sx={{ width: "530px", textAlign: "center" }}>
                  <p className="theatreInfo">
                    Nominated for a META (Montreal English Theatre Award) for
                    <b> Outstanding Supporting Performance</b> in the production
                    of April Fools at the Segal Center for her role as Libido
                    (2022)
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid
            sx={{
              display: { lg: "flex", md: "none", sm: "none", xs: "none" },
              justifyContent: "space-evenly",
              mt: "3em",
            }}
            container
          >
            <Grid item xl={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    maxWidth: 450,
                    maxheight: 450,
                    borderRadius: "0px",
                  }}
                  src={img2}
                  alt="Bring Me Your 'A' Game Album Cover"
                />
                <Box sx={{ width: "500px", textAlign: "center" }}>
                  <p className="theatreInfo">
                    McRoberts as the Baker's Wife in the TIFT Production of Into
                    the Woods (2021)
                  </p>
                </Box>
                <Box sx={{ width: "500px", textAlign: "center" }}>
                  <p className="theatreInfo">
                    “Jamie McRoberts plays the Baker’s Wife with a quiet wisdom
                    and commitment and sings beautifully”<br></br>
                    <span className="quote">
                      - Lynn Slotkin, The Slotkin Letter
                    </span>
                  </p>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    maxWidth: 500,
                    maxheight: 500,
                    borderRadius: "0px",
                  }}
                  src={img6}
                  alt="Bring Me Your 'A' Game Album Cover"
                />
                <Box sx={{ width: "520px", textAlign: "center" }}>
                  <p className="theatreInfo">
                    McRoberts as part of the ensemble in the TIFT production of
                    For Resting and Breeding, performed in Adelaide, Australia
                    (2020)
                  </p>
                </Box>
                <Box sx={{ width: "500px", textAlign: "center" }}>
                  <p className="theatreInfo">
                    “Ish20 (Jamie McRoberts) is the star, bringing humour and
                    pathos to the play.”<br></br>
                    <span className="quote">
                      – Clare Peddie, The Advertiser , Adelaide AU
                    </span>
                  </p>
                </Box>
              </Box>
            </Grid>
            <Button
              className="button"
              href="https://resumes.breakdownexpress.com/one_page_resume.cfm?custom_link=627846-3409514"
              aria-label="resume"
              rel="noopener"
              target="_blank"
              sx={{
                border: "solid 4px",
                borderColor: "#F3BE12",
                width: "250px",
                mt: "5em",
                mb: "2em",
                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                },
              }}
            >
              <p className="buttonText">Full Resume</p>
            </Button>
          </Grid>
        </Container>
      </Grid>
      <TheatreMobile />
    </div>
  );
};

export default TheatreSec;
