import React from "react";
import "./Contact.css";
import { Box } from "@mui/material";
import SMStackFull from "../SMStack/SMStackFull";

const ContactSec = () => {
  return (
    <div className="heroContact">
      {/* Contact section for Lg + */}
      <Box
        sx={{
          display: { lg: "flex", md: "none", sm: "none", xs: "none" },
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: { lg: "flex", md: "none", sm: "none", xs: "none" },
            justifyContent: "start",
            mt: "1em",
            pl: "5em",
            "&:hover": {
              cursor: "default",
            },
          }}
        >
          <h1 className="contactTitle">Contact</h1>
        </Box>
        <Box
          sx={{
            display: { lg: "flex", md: "none", sm: "none", xs: "none" },
            justifyContent: "start",
            pl: "5em",

            "&:hover": {
              cursor: "default",
            },
          }}
        >
          <p className="subContactTitle">All Inquiries:</p>
        </Box>
        <Box
          sx={{
            display: { lg: "flex", md: "none", sm: "none", xs: "none" },
            justifyContent: "start",
            pl: "5em",

            "&:hover": {
              cursor: "default",
            },
          }}
        >
          <p className="contactInfo">Coapro@verizon.net</p>
        </Box>
        <Box
          sx={{
            display: { lg: "flex", md: "none", sm: "none", xs: "none" },
            justifyContent: "center",
            "&:hover": {
              cursor: "default",
            },
          }}
        >
          <p className="signature">Website by Alessandro Vannelli</p>
        </Box>
        {/* <Box
            sx={{
              display: { lg: "flex", md: "none", sm: "none", xs: "none" },
              justifyContent: "end",
              mr: "22.4em",
              mt: "1em",
              "&:hover": {
                cursor: "default",
              },
            }}
          >
            <p className="subContactTitle">Socials:</p>
          </Box>
          <Box
            sx={{
              display: { lg: "flex", md: "none", sm: "none", xs: "none" },
              justifyContent: "end",
              mr: "20.5em",
            }}
          >
            <SMStackFull />
          </Box> */}
      </Box>
      {/* Contact section for M - */}
      <Box
        sx={{
          display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
            justifyContent: "center",
            mt: "1em",
            "&:hover": {
              cursor: "default",
            },
          }}
        >
          <h1 className="contactTitle">Contact</h1>
        </Box>
        <Box
          sx={{
            display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
            justifyContent: "center",

            "&:hover": {
              cursor: "default",
            },
          }}
        >
          <p className="subContactTitle">All Inquiries:</p>
        </Box>
        <Box
          sx={{
            display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
            justifyContent: "center",
            "&:hover": {
              cursor: "default",
            },
          }}
        >
          <p className="contactInfo">Coapro@verizon.net</p>
        </Box>

        <Box
          sx={{
            display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
            justifyContent: "center",
            mt: ".5em",
          }}
        >
          <SMStackFull />
        </Box>
        <Box
          sx={{
            display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
            justifyContent: "center",
            "&:hover": {
              cursor: "default",
            },
          }}
        >
          <p className="signature">Website by Alessandro Vannelli</p>
        </Box>
      </Box>
    </div>
  );
};

export default ContactSec;
