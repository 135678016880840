import React from "react";
import "./Header.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Button, Box, Grid } from "@mui/material";
import { motion } from "framer-motion";
import ReactPlayer from "react-player";
import SMStackFull from "../SMStack/SMStackFull";
import SMStackFullAlt from "../SMStack/SMStackFullAlt";
import img1 from "./../../images/headshot3.jpg";
import img2 from "./../../images/IndieChart.jpg";
import img3 from "./../../images/headshot2.jpg";
import img4 from "./../../images/TorontoAwards.webp";

const Header = () => {
  const scrollToMusic = () => {
    window.scrollTo({
      top: 1000,
      behavior: "smooth",
    });
  };
  const scrollToAbout = () => {
    window.scrollTo({
      top: 4000,
      behavior: "smooth",
    });
  };
  const scrollToTheatre = () => {
    window.scrollTo({
      top: 5000,
      behavior: "smooth",
    });
  };
  const scrollToNews = () => {
    window.scrollTo({
      top: 2410,
      behavior: "smooth",
    });
  };
  const notifyStore = () => {
    toast.error("Store Coming Soon!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <div className="hero">
      <Grid container>
        <Grid item xl={6} lg={6}>
          <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
            <Box
              sx={{
                display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                flexDirection: "row",
                color: "white",
                mt: "6.5em",
                justifyContent: "center",
              }}
            >
              <motion.div onClick={scrollToMusic} whileHover={{ scale: 1.05 }}>
                <Button
                  className="navButton"
                  sx={{
                    fontSize: "1rem",
                    pl: "3em",
                    pr: "3em",
                    color: "white",
                  }}
                >
                  Music
                </Button>
              </motion.div>
              <motion.div onClick={scrollToNews} whileHover={{ scale: 1.05 }}>
                <Button
                  className="navButton"
                  sx={{
                    fontSize: "1rem",
                    pl: "3em",
                    pr: "3em",
                    color: "white",
                  }}
                >
                  News
                </Button>
              </motion.div>
              <motion.div onClick={scrollToAbout} whileHover={{ scale: 1.05 }}>
                <Button
                  className="navButton"
                  sx={{
                    fontSize: "1rem",
                    pl: "3em",
                    pr: "3em",
                    color: "white",
                  }}
                >
                  About
                </Button>
              </motion.div>
              <motion.div
                onClick={scrollToTheatre}
                whileHover={{ scale: 1.05 }}
              >
                <Button
                  className="navButton"
                  sx={{
                    fontSize: "1rem",
                    pl: "4em",
                    pr: "4em",
                    color: "white",
                  }}
                >
                  Theatre
                </Button>
              </motion.div>
              {/* <motion.div
                whileHover={{ scale: 1.05, transition: { duration: 0.1 } }}
              >
                <Button
                  className="navButton"
                  onClick={notifyStore}
                  sx={{
                    fontSize: "1rem",
                    pl: "3em",
                    pr: "3em",
                    color: "white",
                  }}
                >
                  Store
                </Button>
              </motion.div> */}
            </Box>
          </motion.div>
        </Grid>
        <Grid item xl={6} lg={6}>
          <Box
            sx={{
              flexDirection: "column",
              display: { lg: "flex", md: "none", sm: "none", xs: "none" },
              mt: "6em",
              "&:hover": {
                cursor: "default",
              },
            }}
          >
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <h1 className="titleMain">
                  Jamie <br></br>McRoberts
                </h1>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    mb: ".5em",
                    ml: "3em",
                  }}
                >
                  <SMStackFull />
                </Box>
              </Box>
            </motion.div>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <p className="subInfo">
                Award winning actor, singer, dancer, classically trained pianist
                and recording artist
              </p>
            </motion.div>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Box
                sx={{
                  display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                  mt: "2em",
                }}
              >
                <a
                  href="https://www.broadwayworld.com/toronto/article/Winners-Announced-For-The-2023-BroadwayWorld-Toronto-Awards-20240119"
                  aria-label="Canadian Indie Chart - Just Like I Remember #1"
                  rel="noopener"
                  target="_blank"
                >
                  <img
                    style={{
                      width: "24vw",
                      height: "12vw",
                    }}
                    src={img4}
                    alt="Toronto Broadway Music Awards Winners"
                  />
                </a>
              </Box>
              <Box
                sx={{
                  display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                }}
              >
                <a
                  href="https://www.facebook.com/100040809258154/posts/pfbid0p71PLJmr6kjQvaEcBa4rxwmfcGGj6ancgCBUxpGPbagwGNSMRhkAhRZBYksJcLChl/?mibextid=gkx3sN"
                  aria-label="Canadian Indie Chart - Just Like I Remember #1"
                  rel="noopener"
                  target="_blank"
                >
                  <img
                    style={{
                      width: "24vw",
                      height: "12vw",
                    }}
                    src={img2}
                    alt="Canadian Indie Chart - Facebook Post"
                  />
                </a>
              </Box>
            </motion.div>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Button
                className="button"
                href=" https://linktr.ee/jammcr"
                aria-label=""
                rel="noopener"
                target="_blank"
                sx={{
                  display: "flex",
                  border: "solid 4px",
                  borderColor: "#F3BE12",
                  background: "transparent",
                  mt: "3em",
                  width: "250px",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Learn More</h3>
              </Button>
            </motion.div>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Box>
        </Grid>

        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
              mt: "3em",
              textAlign: "center",
            }}
          >
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <h1 className="titleMobile">
                Jamie <br></br>McRoberts
              </h1>
            </motion.div>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <p
                className="subInfoTitleMobile"
                style={{
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  marginTop: "1em",
                }}
              >
                Award winning actor, singer, dancer, classically trained pianist
                and recording artist
              </p>
            </motion.div>
            <Box
              sx={{
                display: { lg: "none", md: "flex", sm: "none", xs: "none" },
                mt: "2em",
                mb: "1em",
              }}
            >
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                {/* <img
                  style={{
                    maxWidth: 350,
                    maxheight: 350,
                  }}
                  src={img1}
                  alt="Bring Me Your 'A' Game Album Cover"
                />
                <img
                  style={{
                    maxWidth: 350,
                    maxheight: 350,
                  }}
                  src={img3}
                  alt="Bring Me Your 'A' Game Back Cover"
                /> */}
                {/* <ReactPlayer
                  controls
                  url={"https://www.youtube.com/watch?v=ELiv1J8NeAg"}
                /> */}

                <a
                  href="https://www.broadwayworld.com/toronto/article/Winners-Announced-For-The-2023-BroadwayWorld-Toronto-Awards-20240119"
                  aria-label="Canadian Indie Chart - Just Like I Remember #1"
                  rel="noopener"
                  target="_blank"
                >
                  <img
                    style={{
                      width: "45vw",
                      height: "23vw",
                    }}
                    src={img4}
                    alt="Toronto Broadwway Awards"
                  />
                </a>
              </motion.div>
            </Box>
            <Box
              sx={{
                display: { lg: "none", md: "flex", sm: "none", xs: "none" },

                mb: "1em",
              }}
            >
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <a
                  href="https://www.facebook.com/100040809258154/posts/pfbid0p71PLJmr6kjQvaEcBa4rxwmfcGGj6ancgCBUxpGPbagwGNSMRhkAhRZBYksJcLChl/?mibextid=gkx3sN"
                  aria-label="Canadian Indie Chart - Just Like I Remember #1"
                  rel="noopener"
                  target="_blank"
                >
                  <img
                    style={{
                      width: "45vw",
                      height: "23vw",
                    }}
                    src={img2}
                    alt="Canadian Indie Chart - Facebook Post"
                  />
                </a>
              </motion.div>
            </Box>

            <Box
              sx={{
                display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
                mt: "2em",
                mb: "2em",
              }}
            >
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                {/* <ReactPlayer
                  controls
                  width="375px"
                  height="250px"
                  url={"https://www.youtube.com/watch?v=ELiv1J8NeAg"}
                /> */}
                <Box sx={{ mt: "1em" }}>
                  <a
                    href="https://www.broadwayworld.com/toronto/article/Winners-Announced-For-The-2023-BroadwayWorld-Toronto-Awards-20240119"
                    aria-label="Canadian Indie Chart - Just Like I Remember #1"
                    rel="noopener"
                    target="_blank"
                  >
                    <img
                      style={{
                        width: "375px",
                        height: "220px",
                      }}
                      src={img4}
                      alt="Toronto Broadway Awards"
                    />
                  </a>
                </Box>
                <Box sx={{ mt: "1em" }}>
                  <a
                    href="https://www.facebook.com/100040809258154/posts/pfbid0p71PLJmr6kjQvaEcBa4rxwmfcGGj6ancgCBUxpGPbagwGNSMRhkAhRZBYksJcLChl/?mibextid=gkx3sN"
                    aria-label="Canadian Indie Chart - Just Like I Remember #1"
                    rel="noopener"
                    target="_blank"
                  >
                    <img
                      style={{
                        width: "375px",
                        height: "220px",
                      }}
                      src={img2}
                      alt="Toronto Broadway Awards"
                    />
                  </a>
                </Box>
              </motion.div>
            </Box>

            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Box sx={{ mt: "0em", ml: "3em" }}>
                <SMStackFullAlt />
              </Box>

              <Button
                href=" https://linktr.ee/jammcr"
                aria-label=""
                rel="noopener"
                target="_blank"
                className="button"
                sx={{
                  display: "flex",
                  border: "solid 4px",
                  borderColor: "#F3BE12",
                  mt: "1em",
                  mb: "5em",
                  width: "250px",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Learn More</h3>
              </Button>
            </motion.div>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer limit={1} />
    </div>
  );
};

export default Header;
