import React from "react";
import "./About.css";
import { Box, Grid } from "@mui/material";
import SMStackFullAlt from "../SMStack/SMStackFullAlt";
import AboutMobile from "./AboutMobile";

const AboutSec = () => {
  return (
    <div>
      <Box
        sx={{ display: { lg: "block", md: "none", sm: "none", xs: "none" } }}
      >
        <div className="heroAbout">
          <Grid container>
            <Grid item xl={6} lg={6}></Grid>
            <Grid item xl={6} lg={6}>
              <Box
                sx={{
                  display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                  flexDirection: "column",
                  mt: "5em",
                  "&:hover": {
                    cursor: "default",
                  },
                }}
              >
                <h1
                  className="aboutTitle"
                  style={{
                    display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                  }}
                >
                  About Me
                </h1>
                <Box sx={{ mr: "10em" }}>
                  <p className="aboutSubInfo">
                    Jamie McRoberts is a singer, actor, dancer and musician from
                    Oakville, Ontario. She is a classically trained pianist and
                    vocalist having completed her Grade 8 Royal Conservatory for
                    Voice and her Grade 10 Royal Conservatory for Piano. She has
                    been training as an actor/performer since the age of 5 and
                    has become an international professional performer, having
                    performed in Canada, Australia and other locations across
                    the world. Recently, McRoberts has rediscovered her musical
                    roots through song-writing, something she defines as the
                    missing piece to her puzzle. This will be showcased in her
                    first ever album, "Bring Me Your 'A' Game", releasing
                    sometime in early 2023.
                  </p>
                </Box>
                <Box
                  sx={{
                    display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                    mt: "1em",
                  }}
                >
                  <SMStackFullAlt />
                </Box>

                <Box sx={{ textAlign: "center", mr: "10em" }}>
                  <p className="testimonial">
                    “Jamie McRoberts is the kind of hot sweet potato you’d
                    gladly serve up with chipotle dipping sauce”<br></br>
                    <span className="testQuote">
                      - Richard Ouzounian, The Toronto Star
                    </span>
                  </p>
                </Box>
                <Box sx={{ textAlign: "center", mr: "10em", mb: "10em" }}>
                  <p className="testimonial">
                    “McRoberts, When she lets fly with Let Yourself Go it’s as
                    if all the fires of hell burn from her throat. More than
                    that, they burn a patch off the stage.”<br></br>
                    <span className="testQuote">
                      – Gary Smith, The Hamilton Spectator
                    </span>
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Box>
      <AboutMobile />
    </div>
  );
};

export default AboutSec;
