import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactPlayer from "react-player/youtube";
import { Box } from "@mui/material";

const YoutubeCar = () => {
  const videoUrls = [
    "https://www.youtube.com/watch?v=C2mNTgiDZdk",
    "https://www.youtube.com/watch?v=uvjTDAUfVvA",
    "https://www.youtube.com/watch?v=ELiv1J8NeAg",
  ];

  return (
    <div style={{}}>
      <Box
        sx={{
          display: { lg: "flex", md: "none", sm: "none", xs: "none" },
          backgroundColor: "#181616cd ",
          padding: "3em",
        }}
      >
        <Carousel
          showStatus={false}
          showThumbs={false}
          infiniteLoop
          useKeyboardArrows
          autoPlay
          interval={8000}
          centerMode
          centerSlidePercentage={33}
          dynamicHeight={false}
          emulateTouch
          swipeScrollTolerance={5}
        >
          {videoUrls.map((url, index) => (
            <div
              key={index}
              style={{
                position: "relative",
              }}
            >
              <ReactPlayer controls url={url} width="100%" style={{}} />
            </div>
          ))}
        </Carousel>
      </Box>
      <Box
        sx={{
          display: { lg: "none", md: "flex", sm: "flex", xs: "none" },
          justifyContent: "center",
          backgroundColor: "#141b22",
          paddingTop: "5em",
          paddingBottom: "5em",
        }}
      >
        <Box sx={{ width: "65%" }}>
          <Carousel
            showStatus={false}
            showThumbs={false}
            infiniteLoop
            useKeyboardArrows
            autoPlay
            interval={10000}
            centerMode
            centerSlidePercentage={100}
            dynamicHeight={false}
            emulateTouch
            swipeScrollTolerance={1}
          >
            {videoUrls.map((url, index) => (
              <div key={index} style={{}}>
                <ReactPlayer controls url={url} width="100%" style={{}} />
              </div>
            ))}
          </Carousel>
        </Box>
      </Box>
    </div>
  );
};

export default YoutubeCar;
