import React from "react";
import "./Theatre.css";
import { Button, Box, Grid } from "@mui/material";

import img1 from "./../../images/DoraAward.jpg";
import img3 from "./../../images/AprilFools1.jpg";
import img4 from "./../../images/DrinkingAlone.jpg";

const TheatreMobile = () => {
  return (
    <Grid container>
      <Grid
        container
        sx={{
          display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
          justifyContent: "center",
        }}
      >
        <Grid item md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              style={{
                maxWidth: 375,
                maxheight: 375,
              }}
              src={img4}
              alt="Bring Me Your 'A' Game Album Cover"
            />
            <Box sx={{ textAlign: "center" }}>
              <p className="theatreInfoMobile">
                Nominated for <b> Best Leading Actress </b> in a play by
                Broadway World for her performance in Drinking Alone by Norm
                Foster (2014)
              </p>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              mt: "3em",
            }}
          >
            <img
              style={{
                maxWidth: 350,
              }}
              src={img1}
              alt="Bring Me Your 'A' Game Album Cover"
            />
            <Box sx={{ textAlign: "center" }}>
              <p className="theatreInfoMobile">
                <b>Dora Award Winner</b> for “best ensemble” in the YPT
                Production of The Wizard of Oz for her role as Glinda the Good
                Witch (2016)
              </p>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              mt: "3em",
            }}
          >
            <img
              style={{
                maxWidth: 375,
                maxheight: 375,
              }}
              src={img3}
              alt="Bring Me Your 'A' Game Album Cover"
            />
            <Box sx={{ textAlign: "center" }}>
              <p className="theatreInfoMobile">
                Nominated for a META (Montreal English Theatre Award) for
                <b> Outstanding Supporting Performance</b> in the production of
                April Fools at the Segal Center for her role as Libido (2022)
              </p>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
          justifyContent: "center",
          mt: "4em",
          mb: "5em",
          "&:hover": {
            cursor: "default",
          },
        }}
      >
        <Button
          className="button"
          href="https://resumes.breakdownexpress.com/one_page_resume.cfm?custom_link=627846-3409514"
          aria-label="resume"
          rel="noopener"
          target="_blank"
          sx={{
            border: "solid 4px",
            borderColor: "#F3BE12",
            width: "250px",
            "&:hover": {
              cursor: "pointer",
              background: "#F3BE12",
            },
          }}
        >
          <p className="buttonText">Full Resume</p>
        </Button>
      </Grid>
    </Grid>
  );
};

export default TheatreMobile;
