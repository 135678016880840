import React from "react";
import "../../App.css";
import { IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useEffect, useState } from "react";

const BackToTopButton = () => {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    backToTopButton && (
      <IconButton id="btnScrollToTop" onClick={scrollUp}>
        <ArrowUpwardIcon sx={{ fontSize: "2.5rem" }}></ArrowUpwardIcon>
      </IconButton>
    )
  );
};

export default BackToTopButton;
