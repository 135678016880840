import React from "react";
import { Container, Button, Box, Grid, Divider } from "@mui/material";
import img1 from "./../../images/JamieHeadshot.jpg";
import img2 from "./../../images/AboutBack.jpeg";
import img3 from "./../../images/headshot3.jpg";
import "./News.css";

const NewsSec = () => {
  return (
    <div style={{ background: "#0B0B0B", color: "#ecf0f3" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: "2em",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
            justifyContent: "center",
            mt: "1em",
          }}
        >
          <h1 className="newsTitle">News</h1>
        </Box>
        <Box
          sx={{
            display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
            justifyContent: "center",
            mb: "1em",
          }}
        >
          <h1
            className="newsTitleMobile"
            style={{
              display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              justifyContent: "center",
              marginTop: "1em",
            }}
          >
            News
          </h1>
        </Box>
      </Box>

      {/* Articles for Md+ */}
      <Grid
        spacing={0}
        sx={{
          display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
        }}
        container
      >
        <Grid item xl={4} lg={4} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: "2em",
            }}
          >
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <a
                href="https://www.broadwayworld.com/toronto/article/Winners-Announced-For-The-2023-BroadwayWorld-Toronto-Awards-20240119"
                aria-label="Toronto BroadwayWorld Awards - Maggie"
                rel="noopener"
                target="_blank"
              >
                <img
                  style={{
                    width: "30vw",
                    height: "22vw",
                  }}
                  src={img2}
                  alt="Find Your Sounds - Article"
                />
              </a>
            </Box>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "400px",
                mt: "1em",
              }}
            >
              <p className="newsInfo">
                <b>2023 BroadwayWorld Toronto Awards :</b> Jamie McRoberts wins
                Best Supporting Performer in a Musical for her role in "Maggie"
              </p>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={4}>
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <a
                href="https://tinnitist.com/2023/06/07/jamie-mcroberts-bounces-back-with-just-like-i-remember/"
                aria-label="Tinnitist - Just Like I Remember"
                rel="noopener"
                target="_blank"
              >
                <img
                  style={{
                    width: "17vw",
                    height: "22vw",
                  }}
                  src={img1}
                  alt="Tinnitist - Article"
                />
              </a>
            </Box>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "300px",
                mt: "1em",
              }}
            >
              <p className="newsInfo">
                <b>Tinnitist:</b> Jamie McRoberts Bounces Back With Just Like I
                Remember
              </p>
            </Box>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <a
                href="https://www.facebook.com/100040809258154/posts/pfbid0p71PLJmr6kjQvaEcBa4rxwmfcGGj6ancgCBUxpGPbagwGNSMRhkAhRZBYksJcLChl/?mibextid=gkx3sN"
                aria-label="Canadian Indie Chart - Just Like I Remember #1"
                rel="noopener"
                target="_blank"
              >
                <img
                  style={{
                    width: "17vw",
                    height: "22vw",
                  }}
                  src={img1}
                  alt="Canadian Indie Chart - Facebook Post"
                />
              </a>
            </Box>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "300px",
                mt: "1em",
              }}
            >
              <p className="newsInfo">
                <b>Canadian Indie Country Countdown:</b> Jamie McRobert's "Just
                Like I Remember" reaches number 1 on the Canadian Indie Country
                Countdown! Remember
              </p>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <a
                href="https://www.pumpitupmagazine.com/music-review-jamie-mcroberts-delivers-a-resilient-and-uplifting-anthem-with-just-like-i-remember/"
                aria-label="Pump It Up Magazine - Just Like I Remember"
                rel="noopener"
                target="_blank"
              >
                <img
                  style={{
                    width: "26vw",
                    height: "22vw",
                  }}
                  src={img3}
                  alt="Pump It Up Magazine - Article"
                />
              </a>
            </Box>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "400px",
                mt: "1em",
              }}
            >
              <p className="newsInfo">
                <b>PumpItUp Magazine:</b> Jamie McRoberts Delivers a Resilient
                and Uplifting Anthem with “Just Like I Remember”
              </p>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Articles for Sm- */}
      <Grid
        spacing={0}
        sx={{
          display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
        }}
        container
      >
        <Grid item sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: "3em",
            }}
          >
            <Box
              sx={{
                display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <a
                href="https://www.broadwayworld.com/toronto/article/Winners-Announced-For-The-2023-BroadwayWorld-Toronto-Awards-20240119"
                aria-label="Toronto BroadwayWorld Music Awards - Maggie"
                rel="noopener"
                target="_blank"
              >
                <img
                  style={{
                    width: "55vw",
                    height: "40vw",
                  }}
                  src={img2}
                  alt="BroadwayWorld Music Awards - Article"
                />
              </a>
            </Box>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "300px",
                mt: "1em",
              }}
            >
              <p className="newsInfo">
                <b>2023 BroadwayWorld Toronto Awards:</b> Jamie McRoberts wins
                Best Supporting Performer in a Musical for her role in "Maggie"
              </p>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: "3em",
            }}
          >
            <Box
              sx={{
                display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <a
                href="https://www.facebook.com/100040809258154/posts/pfbid0p71PLJmr6kjQvaEcBa4rxwmfcGGj6ancgCBUxpGPbagwGNSMRhkAhRZBYksJcLChl/?mibextid=gkx3sN"
                aria-label="Canadian Indie Chart - Just Like I Remember #1"
                rel="noopener"
                target="_blank"
              >
                <img
                  style={{
                    width: "40vw",
                    height: "50vw",
                  }}
                  src={img1}
                  alt="Canadian Indie Chart - Facebook Post"
                />
              </a>
            </Box>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "300px",
                mt: "1em",
              }}
            >
              <p className="newsInfo">
                <b>Canadian Indie Country Countdown:</b> Jamie McRobert's "Just
                Like I Remember" reaches number 1 on the Canadian Indie Country
                Countdown! Remember
              </p>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: "3em",
            }}
          >
            <Box
              sx={{
                display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <a
                href="https://www.pumpitupmagazine.com/music-review-jamie-mcroberts-delivers-a-resilient-and-uplifting-anthem-with-just-like-i-remember/"
                aria-label="Pump It Up Magazine - Just Like I Remember"
                rel="noopener"
                target="_blank"
              >
                <img
                  style={{
                    width: "50vw",
                    height: "40vw",
                  }}
                  src={img3}
                  alt="Pump It Up Magazine - Article"
                />
              </a>
            </Box>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "300px",
                mt: "1em",
              }}
            >
              <p className="newsInfo">
                <b>PumpItUp Magazine:</b> Jamie McRoberts Delivers a Resilient
                and Uplifting Anthem with “Just Like I Remember”
              </p>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "center", pb: "3em" }}>
        <Button
          href="https://linktr.ee/jammcr"
          aria-label="LinkTree"
          rel="noopener"
          target="_blank"
          sx={{
            display: "flex",
            width: "195px",
            height: "50px",
            mt: "2em",
            border: "solid 3px",
            borderColor: "#F3BE12",
            "&:hover": {
              cursor: "pointer",
              background: "#F3BE12",
            },
          }}
        >
          <h3 className="buttonNewsTour">See More</h3>
        </Button>
      </Box>

      {/* Divider */}
      <Container>
        <Divider
          sx={{
            display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
            background: "white",
          }}
        ></Divider>
      </Container>
    </div>
  );
};

export default NewsSec;
