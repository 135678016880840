import * as React from "react";
import Stack from "@mui/material/Stack";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import { motion } from "framer-motion";

const SMStackFullAlt = () => {
  return (
    <Stack direction="row" spacing={1}>
      <motion.div
        whileHover={{
          scale: 1.1,
          transition: { type: "spring", duration: 0.5 },
        }}
      >
        <Button
          href="https://www.instagram.com/jamieamcroberts77/"
          aria-label="instagram"
          rel="noopener"
          target="_blank"
          sx={{
            fontSize: 55,
            borderRadius: 100,
            color: "white",
            "&:hover": {
              cursor: "pointer",
              background: "#F3BE12",
            },
          }}
        >
          <Icon icon="bi:instagram" />
        </Button>
      </motion.div>
      {/* <Button
        href="https://www.facebook.com/rossvannelli"
        aria-label="facebook"
        rel="noopener"
        target="_blank"
        sx={{
          fontSize: 55,
          borderRadius: 100,
          color: "white",
          "&:hover": {
            cursor: "pointer",
            background: "#F3BE12",
          },
        }}
      >
        <Icon icon="bi:twitter" />
      </Button> */}
      <motion.div
        whileHover={{
          scale: 1.1,
          transition: { type: "spring", duration: 0.5 },
        }}
      >
        <Button
          href="https://www.facebook.com/jamie.mcroberts.3"
          aria-label="facebook"
          rel="noopener"
          target="_blank"
          sx={{
            fontSize: 55,
            borderRadius: 100,
            color: "white",
            "&:hover": {
              cursor: "pointer",
              background: "#F3BE12",
            },
          }}
        >
          <Icon icon="bi:facebook" />
        </Button>
      </motion.div>
      {/* <Button
        href="https://www.facebook.com/rossvannelli"
        aria-label="youtube"
        rel="noopener"
        target="_blank"
        sx={{
          fontSize: 55,
          borderRadius: 100,
          color: "white",
          "&:hover": {
            cursor: "pointer",
            background: "#F3BE12",
          },
        }}
      >
        <Icon icon="bi:youtube" />
      </Button> */}
    </Stack>
  );
};

export default SMStackFullAlt;
