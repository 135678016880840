import * as React from "react";
import Stack from "@mui/material/Stack";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import { motion } from "framer-motion";

const SMStackFull = () => {
  return (
    <Stack direction="row" spacing={0.3}>
      <Button
        href="https://www.instagram.com/jamieamcroberts77/"
        aria-label="instagram"
        rel="noopener"
        target="_blank"
        sx={{
          fontSize: 40,
          borderRadius: 100,
          color: "white",
          "&:hover": {
            cursor: "pointer",
            background: "#F3BE12",
          },
        }}
      >
        <Icon icon="bi:instagram" />
      </Button>

      {/* <Button
        href="https://www.facebook.com/rossvannelli"
        aria-label="facebook"
        rel="noopener"
        target="_blank"
        sx={{
          fontSize: 40,
          borderRadius: 100,
          color: "white",
          "&:hover": {
            cursor: "pointer",
            background: "#F3BE12",
          },
        }}
      >
        <Icon icon="bi:twitter" />
      </Button> */}

      <Button
        href="https://www.facebook.com/jamieamcroberts77"
        aria-label="facebook"
        rel="noopener"
        target="_blank"
        sx={{
          fontSize: 40,
          borderRadius: 100,
          color: "white",
          "&:hover": {
            cursor: "pointer",
            background: "#F3BE12",
          },
        }}
      >
        <Icon icon="bi:facebook" />
      </Button>

      {/* <Button
        href="https://www.facebook.com/rossvannelli"
        aria-label="youtube"
        rel="noopener"
        target="_blank"
        sx={{
          fontSize: 40,
          borderRadius: 100,
          color: "white",
          "&:hover": {
            cursor: "pointer",
            background: "#F3BE12",
            color: "white",
          },
        }}
      >
        <Icon icon="bi:youtube" />
      </Button> */}
    </Stack>
  );
};

export default SMStackFull;

// import { useNavigate } from "react-router-dom";
// let navigate = useNavigate();
// onClick={() => navigate("/")}
