import React from "react";
import "./About.css";
import { Box } from "@mui/material";
import img1 from "./../../images/AboutBackNew.jpeg";

const AboutMobile = () => {
  return (
    <div style={{ background: "#0B0B0B", color: "#ecf0f3" }}>
      <Box
        sx={{
          display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
          justifyContent: "center",
        }}
      >
        <img
          style={{
            maxWidth: 375,
            maxheight: 375,
            borderRadius: 0,
            marginTop: "3em",
          }}
          src={img1}
          alt="Bring Me Your 'A' Game Album Cover"
        />
      </Box>
      <Box
        sx={{
          display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          pl: "3em",
          pr: "3em",
          pt: "2em",
        }}
      >
        <h1 className="aboutTitle">About Me</h1>
        <p className="subInfoMobile">
          Jamie McRoberts is a singer, actor, dancer and musician from Oakville,
          Ontario. She is a classically trained pianist and vocalist having
          completed her Grade 8 Royal Conservatory for Voice and her Grade 10
          Royal Conservatory for Piano. She has been training as an
          actor/performer since the age of 5 and has become an international
          professional performer, having performed in Canada, Australia and
          other locations across the world. Recently, McRoberts has rediscovered
          her musical roots through song-writing, something she defines as the
          missing piece to her puzzle. This will be showcased in her first ever
          album, "Bring Me Your 'A' Game", releasing sometime in early 2023.
        </p>
      </Box>
    </div>
  );
};

export default AboutMobile;
