import React from "react";
import "./Music.css";
import img1 from "./../../images/AGAMESINGLEPROMO.jpg";
import img2 from "./../../images/JamiePromo.jpg";
import img3 from "./../../images/AGameNew.jpg";
import img4 from "./../../images/HOOTDPromo.jpg";
import SMStack from "../SMStack/SMStack";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid, Button, Box, Container } from "@mui/material";

const MusicNewSec = () => {
  const notifyMusic = () => {
    toast.success("Album Coming 2023!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };
  return (
    <div style={{ background: "#0B0B0B", color: "#ecf0f3" }}>
      <Grid
        container
        columnSpacing={0}
        sx={{ display: "flex", pl: "4em", pr: "4em" }}
      >
        <Grid
          item
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "3em",
            }}
          >
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
              }}
            >
              <img
                style={{
                  minWidth: 240,
                  height: 400,
                }}
                src={img4}
                alt="Having One of Those Days Promo"
              />
            </Box>
            <Box
              sx={{
                display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              }}
            >
              <img
                style={{
                  maxWidth: 300,
                  maxheight: 450,
                }}
                src={img4}
                alt="Having One of Those Days Promo"
              />
            </Box>
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                flexDirection: "column",
                justifyContent: "center",

                ml: "2em",
                mb: "2em",
                "&:hover": {
                  cursor: "default",
                },
              }}
            >
              <h2
                className="songName"
                style={{
                  justifyContent: "start",
                  marginRight: "2.3em",
                  textAlign: "center",
                }}
              >
                Having One of Those Days <br></br>
                (Single)
                <br></br>
              </h2>

              <Button
                href=" https://distrokid.com/hyperfollow/jamiemcroberts/having-one-of-those-days"
                aria-label=""
                rel="noopener"
                target="_blank"
                className="button"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "solid 4px",
                  borderColor: "#F3BE12",
                  mt: "1em",
                  ml: "6em",

                  width: "250px",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Stream + Buy</h3>
              </Button>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  mt: "1em",
                  ml: "8em",
                }}
              >
                <SMStack />
              </Box>
              <Box
                sx={{
                  display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                  mt: "4em",
                  mb: "0em",
                  width: "500px",
                  pr: "5em",
                  "&:hover": {
                    cursor: "default",
                  },
                }}
              >
                <p className="specialInfo">
                  <b>"Having One of Those Days"</b> is the third single
                  releasing July 28th, 2023 from Jamie McRobert's upcoming
                  album. Produced by Ross Vannelli. Video directed and edited by
                  Ross Vannelli.
                </p>
              </Box>
            </Box>
          </Box>
          {/* Music section for Sm - */}
          <Box
            sx={{
              display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              mb: "2em",
              "&:hover": {
                cursor: "default",
              },
            }}
          >
            {/* <h2
              className="songName"
              style={{
                display: "block",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              Just Like I Remember
            </h2> */}

            <Button
              href=" https://distrokid.com/hyperfollow/jamiemcroberts/having-one-of-those-days"
              aria-label=""
              rel="noopener"
              target="_blank"
              className="button"
              sx={{
                display: "flex",
                justifyContent: "center",
                border: "solid 4px",
                borderColor: "#F3BE12",
                width: "250px",

                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                },
              }}
            >
              <h3 className="buttonText">Stream + Buy</h3>
            </Button>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "1em",
                mb: "0em",
              }}
            >
              <SMStack />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "3em",
            }}
          >
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
              }}
            >
              <img
                style={{
                  minWidth: 240,
                  height: 400,
                }}
                src={img1}
                alt="A Game Single Promotion"
              />
            </Box>
            <Box
              sx={{
                display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              }}
            >
              <img
                style={{
                  maxWidth: 300,
                  maxheight: 450,
                }}
                src={img1}
                alt="Just Like I Remember Promotion"
              />
            </Box>
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                flexDirection: "column",
                justifyContent: "center",

                ml: "2em",
                mb: "2em",
                "&:hover": {
                  cursor: "default",
                },
              }}
            >
              <h2
                className="songName"
                style={{
                  justifyContent: "start",
                  marginRight: "2.3em",
                  textAlign: "center",
                }}
              >
                Bring Me Your<span className="red">'A' Game </span> <br></br>
                (Single)
              </h2>

              <Button
                href=" https://distrokid.com/hyperfollow/jamiemcroberts/bring-me-your-a-game-3"
                aria-label=""
                rel="noopener"
                target="_blank"
                className="button"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "solid 4px",
                  borderColor: "#F3BE12",
                  mt: "1em",
                  ml: "6em",

                  width: "250px",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Stream + Buy</h3>
              </Button>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  mt: "1em",
                  ml: "8em",
                }}
              >
                <SMStack />
              </Box>
              <Box
                sx={{
                  display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                  mt: "4em",
                  mb: "0em",
                  width: "500px",
                  pr: "5em",
                  "&:hover": {
                    cursor: "default",
                  },
                }}
              >
                <p className="specialInfo">
                  "Bring Me Your 'A' Game" is the second single from Jamie
                  McRoberts album entitled Bring Me Your ‘A’ Game, which
                  released June 16th, 2023.
                </p>
              </Box>
            </Box>
          </Box>
          {/* Music section for Sm - */}
          <Box
            sx={{
              display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              mb: "2em",
              "&:hover": {
                cursor: "default",
              },
            }}
          >
            {/* <h2
              className="songName"
              style={{
                display: "block",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              Just Like I Remember
            </h2> */}

            <Button
              href=" https://distrokid.com/hyperfollow/jamiemcroberts/bring-me-your-a-game-3"
              aria-label=""
              rel="noopener"
              target="_blank"
              className="button"
              sx={{
                display: "flex",
                justifyContent: "center",
                border: "solid 4px",
                borderColor: "#F3BE12",
                width: "250px",

                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                },
              }}
            >
              <h3 className="buttonText">Stream + Buy</h3>
            </Button>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "1em",
                mb: "0em",
              }}
            >
              <SMStack />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            mb: "3em",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", mt: "3em" }}>
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
              }}
            >
              <img
                style={{
                  minWidth: 240,
                  height: 400,
                }}
                src={img3}
                alt="A Game Promotion"
              />
            </Box>
            <Box
              sx={{
                display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              }}
            >
              <img
                style={{
                  maxWidth: 300,
                  maxheight: 450,
                }}
                src={img3}
                alt="A Game Promotion Promotion"
              />
            </Box>
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                flexDirection: "column",
                justifyContent: "center",

                ml: "2em",
                "&:hover": {
                  cursor: "default",
                },
              }}
            >
              <h2
                className="songName"
                style={{
                  justifyContent: "start",
                  marginRight: "2.3em",
                  textAlign: "center",
                }}
              >
                Bring Me Your<span className="red">'A' Game </span> <br></br>
                (Album)
              </h2>

              <Button
                href=" https://distrokid.com/hyperfollow/jamiemcroberts/bring-me-your-a-game-2 "
                aria-label=""
                rel="noopener"
                target="_blank"
                className="button"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "solid 4px",
                  borderColor: "#F3BE12",
                  mt: "1em",
                  ml: "6em",

                  width: "250px",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Stream + Buy</h3>
              </Button>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  mt: "1em",
                  ml: "8em",
                }}
              >
                <SMStack />
              </Box>
              <Box
                sx={{
                  display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                  mt: "5em",
                  mb: "0em",
                  width: "500px",
                  pr: "5em",
                  "&:hover": {
                    cursor: "default",
                  },
                }}
              >
                <p className="specialInfo">
                  Bring Me Your 'A' Game is Jamie McRoberts first ever album
                  released May 26th, 2023. Produced by Ross Vannelli. Jamie and
                  Ross penned eleven original tunes for her debut album.
                </p>
              </Box>
            </Box>
          </Box>
          {/* Music section for Sm - */}
          <Box
            sx={{
              display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              mt: "2em",

              "&:hover": {
                cursor: "default",
              },
            }}
          >
            {/* <h2
              className="songName"
              style={{
                display: "block",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              Bring Me Your <span className="red">'A' Game</span>
            </h2> */}

            <Button
              href=" https://distrokid.com/hyperfollow/jamiemcroberts/bring-me-your-a-game-2 "
              aria-label=""
              rel="noopener"
              target="_blank"
              className="button"
              sx={{
                display: "flex",
                justifyContent: "center",
                border: "solid 4px",
                borderColor: "#F3BE12",
                mt: ".5em",

                width: "250px",

                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                },
              }}
            >
              <h3 className="buttonText">Stream + Buy</h3>
            </Button>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "1em",
                mb: "0em",
              }}
            >
              <SMStack />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", mt: "3em" }}>
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
              }}
            >
              <img
                style={{
                  minWidth: 240,
                  height: 400,
                }}
                src={img2}
                alt="Just Like I Remember Promotion"
              />
            </Box>
            <Box
              sx={{
                display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              }}
            >
              <img
                style={{
                  maxWidth: 300,
                  maxheight: 450,
                }}
                src={img2}
                alt="Just Like I Remember Promotion"
              />
            </Box>
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                flexDirection: "column",
                justifyContent: "center",

                ml: "2em",
                mb: "2em",
                "&:hover": {
                  cursor: "default",
                },
              }}
            >
              <h2
                className="songName"
                style={{
                  justifyContent: "start",
                  marginRight: "2.2em",

                  textAlign: "center",
                }}
              >
                Just Like I Remember <br></br> (Single)
              </h2>

              <Button
                href=" https://distrokid.com/hyperfollow/jamiemcroberts/just-like-i-remember"
                aria-label=""
                rel="noopener"
                target="_blank"
                className="button"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "solid 4px",
                  borderColor: "#F3BE12",
                  mt: "1em",
                  ml: "6em",

                  width: "250px",
                  "&:hover": {
                    cursor: "pointer",
                    background: "#F3BE12",
                  },
                }}
              >
                <h3 className="buttonText">Stream + Buy</h3>
              </Button>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  mt: "1em",
                  ml: "8em",
                }}
              >
                <SMStack />
              </Box>
              <Box
                sx={{
                  display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
                  mt: "5em",
                  mb: "0em",
                  width: "500px",
                  pr: "5em",
                  "&:hover": {
                    cursor: "default",
                  },
                }}
              >
                <p className="specialInfo">
                  "Just Like I Remember" is the second single from Jamie
                  McRoberts and has achieved the Number 1 spot on the Canadian
                  Indie Country Countdown!
                </p>
              </Box>
            </Box>
          </Box>
          {/* Music section for Sm - */}
          <Box
            sx={{
              display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              mt: "2em",
              mb: "2em",
              "&:hover": {
                cursor: "default",
              },
            }}
          >
            {/* <h2
              className="songName"
              style={{
                display: "block",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              Just Like I Remember
            </h2> */}

            <Button
              href=" https://distrokid.com/hyperfollow/jamiemcroberts/just-like-i-remember"
              aria-label=""
              rel="noopener"
              target="_blank"
              className="button"
              sx={{
                display: "flex",
                justifyContent: "center",
                border: "solid 4px",
                borderColor: "#F3BE12",

                width: "250px",

                "&:hover": {
                  cursor: "pointer",
                  background: "#F3BE12",
                },
              }}
            >
              <h3 className="buttonText">Stream + Buy</h3>
            </Button>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "1em",
                mb: "0em",
              }}
            >
              <SMStack />
            </Box>
          </Box>
        </Grid>

        <ToastContainer limit={1} />
      </Grid>
    </div>
  );
};

export default MusicNewSec;
