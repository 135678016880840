import React from "react";
import Header from "../Components/Head/Header";
import MusicNew from "../Components/Music/MusicNew";
import About from "../Components/About/About";
import Theatre from "../Components/Theatre/Theatre";
import Contact from "../Components/Contact/Contact";
import BackToTopButton from "../Components/BackToTop/BackToTop";
import Upcoming from "../Components/Upcoming/Upcoming.jsx";
import News from "../Components/News/News.jsx";

import "../App.css";
import YoutubeCar from "../Components/YoutubeCar/YoutubeCar";

const HomePage = () => {
  return (
    <div>
      <Header />
      <MusicNew />
      <YoutubeCar />
      <News />
      <Upcoming />
      <About />
      <Theatre />
      <Contact />
      <BackToTopButton />
    </div>
  );
};

export default HomePage;
